import React from 'react';
import './resources.css';
import { resourcesData } from './components/resourcesData';
import { ResourcesAccordion } from './components/resourcesAccordion';

export const Resources = () => {
  return (
    <section className='resources-container'>
      <h1 className='section-title'>My <span>Resources</span></h1>
        <p className='resources-description'>
          This page offers resources I refer to in my research.
        </p>

        <div className="resources-accordion"></div>
        {resourcesData.map((section, index) => (
          <ResourcesAccordion key={index} section={section} />
        ))}
    </section>
  )
}
