import React from 'react'
import './about.css'
import { AboutPhotos } from './components/aboutPhotos'
import { EducationItem } from './components/educationItem'
import { educationData } from './components/educationData'
import { FaDownload } from 'react-icons/fa'
import CV from '../util/CV/CV_MClements.pdf'
import { RiWomenLine } from 'react-icons/ri'
import  {GiArchiveResearch} from 'react-icons/gi'
import {SlSpeech} from 'react-icons/sl'
import {MdOutlineHealthAndSafety} from 'react-icons/md'


export const About = () => {
  return (
  <main className= 'section-container'>
    <section className='about'>
        <h2 className= 'section-title'>About <span> Me </span>
        </h2>

        <div className='about-page-container grid'>
            <div className='photo grid'>
                <AboutPhotos/>
            </div>
            <div className='separator about-separator'></div>
            <div className='about-description-container'>
            <section className='about-description'>
                <p>I am an Assistant Professor at The University of Tampa in the Communication Department, and I served as Director of 
                    The Center for Public Speaking from 2018-2022. My mission is to advance patient-centered communication. I use mostly 
                    qualitative methods to examine relational and political problems related to health, gender, and technology in organizations.
                    I also specialize in public speaking and leadership in professional settings. 
                </p>
            </section>
            <a href={CV} download="" className="button resume-button about-resume-button">
                        Download CV <span className="button-icon resume-button-icon">
                            <FaDownload/>
                        </span>
            </a>
            </div>
    
        </div>
    </section>

    



    <div className='separator'></div>

    <a href={CV} download="" className="button resume-button">
                        Download CV <span className="button-icon resume-button-icon">
                            <FaDownload/>
                        </span>
    </a>
    
    <div className='separator'></div>

        <section className='education'>
            <div className='education-container grid'>
                <div className='education-data'>
                <h3 className = 'section-subtitle subtitle-center'>Education</h3>
                    {educationData.map((val) => {
                        if (val.category === 'education') {
                            return <EducationItem key={val.id} {...val}/>;
                        }
                    })}
                </div>
                <div className='interest-container'>
                {/* <div className='about-info'> */}
                    <h3 className='section-subtitle interest-title'>Interests</h3>
                     <ul className='info-list grid'>
                         <li className='interest-list-container'>
                            <div className='interest-icon'>
                                <MdOutlineHealthAndSafety />
                            </div> 
                            <div>
                                <p className = 'interest-list-title'>Health Communication</p>
                            </div>
                            </li>
                            <li className='interest-list-container'>
                            <div className='interest-icon'>
                                <SlSpeech />
                            </div>
                            <div>
                                <p className = 'interest-list-title'>Public Speaking</p>
                            </div>
                            </li>
                            <li className='interest-list-container'>
                            <div className='interest-icon'>
                                <GiArchiveResearch />
                            </div>
                            <div>
                                <p className = 'interest-list-title'>Qualitative Research Methods</p>
                                </div>
                            </li>
                            <li className='interest-list-container'>
                            <div className='interest-icon'>
                                <RiWomenLine />
                            </div>
                            <div>
                                <p className = 'interest-list-title'>Women's & Gender Studies</p>
                                </div>
                             </li>
                            </ul>
                            </div>
                        </div>
            {/* </div> */}
        </section>
    </main>
  )
}
