import React from "react";
import { FaDownload } from "react-icons/fa";
import patient from '../../util/imgs/research/patient.jpeg'
import healthIT from '../../util/imgs/research/healthIT.jpeg'
import art from '../../util/imgs/research/art.jpeg'
import information from '../../util/imgs/research/information.jpeg'
import students from '../../util/imgs/research/students.jpeg'
import methods from '../../util/imgs/research/methods.jpeg'
import article1 from '../../util/researchPDF/square1/I Can’t be Bald at Work.pdf'
import article2 from '../../util/researchPDF/square1/Time spent waiting.pdf'
import article3 from '../../util/researchPDF/square1/Previving.pdf'
import article4 from '../../util/researchPDF/square1/Assessing Patient Satisfaction.pdf'
import article5 from '../../util/researchPDF/square1/Intersections of health and gender imperatives.pdf'
import article6 from '../../util/researchPDF/square1/Quality of life and clippers.pdf'
import article7 from '../../util/researchPDF/square1/Bald and beautiful.pdf'
import article8 from '../../util/researchPDF/square3/I will hold your hand through my disease.pdf'
import article9 from '../../util/researchPDF/square3/An Arts-Based Workshop.pdf'
import article10 from '../../util/researchPDF/square3/Lie To Me.pdf'
import article11 from '../../util/researchPDF/square3/17 Lines About Chemo.pdf'
import article12 from '../../util/researchPDF/square3/Having Cancer.pdf'
import article13 from '../../util/researchPDF/square4/Previvors Perceptions.pdf'
import article14 from '../../util/researchPDF/square5/The Mentoring Frame.pdf'
import article15 from '../../util/researchPDF/square5/Student Perceptions.pdf'

export const researchData = [
{
    id:1,
    img: patient,
    title: 'Patient Experience',
    details: [
        {
            title:'I Can’t be Bald at Work',
            desc:'This article calls attention to the rigid standards of the American workplace that are rooted in masculine expectations that celebrate conformity and sacrifice. Questions are raised related to whether there is room in respectability politics for the terminally ill as well as if today’s workplace yields space for an aesthetic of disease',
            button: <a href={article1} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Time spent waiting: Attempting to perform the “good daughter” and missing opportunities to practice empathy',
            desc: 'This narrative shares my experience as a daughter who struggles to make sense of the “in-between” time while waiting for my mother’s operation to remove her brain tumor. I confront the “good daughter” narrative I inherited from social and familial expectations and question the ways I reinforced instead of looking for ways to communicate empathy.',
            button: <a href={article2} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Previving: How Unaffected Women with a BRCA1/2 Mutation Navigate Previvor Identity',
            desc:`The term “previvor” refers to women (or men) who test positive for the BRCA genetic mutation but do not have a cancer diagnosis. “Pre” suggest the notion of “it's not if, but when I get cancer.” This piece unpacks the complexity of “previvor” as an identity marker from the perspectives of BRCA patients.`,
            button: <a href={article3} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Assessing patient satisfaction: Using the radiation oncology patient satisfaction [ROPS] questionnaire in a private practice setting',
            desc: `My research team developed a new method/measure to examine patient satisfaction. The measure is called the Radiation Oncology Patient Satisfaction (ROPS) questionnaire, and we tested its value and validity in a radiation oncology clinic. Participants' responses (n=950) indicated organizational setting was of vital importance when conducting patient-centered research on satisfaction. ROPS can be adjusted to measure satisfaction with chemotherapy or other modalities in addition to radiation treatment.`,
            button: <a href={article4} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Intersections of health and gender imperatives: Stratified decision-making among women with a BRCA mutation',
            desc: `This is a qualitative health piece about decision-making among women with the genetic mutations “BRCA 1/2,” which are the “breast cancer genes.” Our findings discuss how older women who were married with children experienced alignment between gendered expectations and health expectations. In contrast, younger single women without children experienced conflicting decision-making concerning managing their health and enacting gendered expectations, resulting in the prioritization of gender imperatives.`,
            button: <a href={article5} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Quality of life and clippers: Decision-making during chemotherapy-induced alopecia',
            desc: `This qualitative study introduced a strategic decision-making model clinicians can use to help patients cope with alopecia during chemotherapy.`,
            button: <a href={article6} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Bald and beautiful? Quality-of-life and chemotherapy-induced alopecia',
            button: <a href={article7} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: 'Ovarian and Uterine Cancer Patients and the Aesthetics of Disease'
        }
    ]
},
{
    id:2,
    img: healthIT,
    title: 'Health Information Technology',
    details: [
        {
            title:'Medical Records, Electronic and Patient Portals',
            desc:`This is a reference piece about the U.S. government mandate to implement electronic medical records (EMR) as well as how EMR and portal technology are used in healthcare.`,
        },
        {
            title: 'Patient portals: The adoption and implementation process of a new technology',
            desc: 'This research seeks examines the diffusion process of portal technology in private medical practices. Information technology develops at a rapid pace, yet timely development does not guarantee effective dissemination. This disconnect may occur because new technologies enter existing structures with varying degrees of openness toward organizational change.',
        }
    ]
},
{
    id:3,
    img: art,
    title: 'Arts-Based Research',
    details: [
        {
            title:`“I will hold your hand through my disease:” Using poetic narrative to interpret the role of
            mother/patient during advanced-stage cancer`,
            desc:`Mothering is a daily practice that does not subside during cancer, and many patients locate their
            struggle of performing “patient” within the struggle of performing “mother” during cancer
            treatment. This piece moves health communication forward by reframing “patient-centered” as
            “woman-centered.”`,
            button: <a href={article8} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: `An arts-based workshop using scalp tattoos to connect students with cancer patients’ experiences
            of chemotherapy and hair loss.`,
            desc: `This article advances arts-based pedagogy and qualitative methods in health communication. My
            team of student-researchers and I share learning outcomes and &quot;how to&quot; steps for replicating the
            workshop.`,
            button: <a href={article9} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: `Lie to me: Tell me bald is beautiful`,
            button: <a href={article10} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: `17 Lines About Chemo`,
            button: <a href={article11} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: `Having Cancer and Seeking the Point`,
            button: <a href={article12} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        }
    ]
},
{
    id:4,
    img: information,
    title: 'Risk and Information Seeking',
    details: [
        {
            title:`“When information is not enough”: A model for understanding BRCA-positive previvors’
            information needs regarding hereditary breast and ovarian cancer risk`,
            desc:'',
        },
        {
            title: `Previvors’ perceptions of hereditary breast and ovarian cancer health information`,
            desc: '',
            button: <a href={article13} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        }
    ]
},
{
    id:5,
    img: students,
    title: 'Student-Centered',
    details: [
        {
            title:'The mentoring frame: Student-preceptor relationships in medical education',
            desc:`This article posits mentoring as a frame used to make sense of the medical student-preceptor relationship. Combining the theoretical conceptions of Goffman (1974) and Weick (1979) with an analysis of electronic journal entries of medical students and preceptors at a large Southeastern medical school, this qualitative study suggests participants (re)construct roles that hold “mentor” and “mentee” to an almost impossible standard of excellence. As a result, opportunities for an open exchange of expectations among participants are often missed.`,
            button: <a href={article14} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        },
        {
            title: `Student perceptions of instructor-student rapport and motivation in hybrid courses during COVID-19`,
            desc: `This interpersonal communication piece examines undergraduates’ perceptions of online and
            face-to-face learning during COVID. Our findings indicate a significant positive relationship
            between instructor-student rapport and student motivation in both online and face-to-face classes.`,
            button: <a href={article15} download="" className="button research-button">Download Article <span className="button-icon research-button-icon"><FaDownload/></span></a>
        }
    ]
},
{
    id:6,
    img: methods,
    title: 'Methods',
    details: [
        {
            title:'',
            desc:'',
        }
    ]
},
]