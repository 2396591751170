import React from 'react';
import parse from 'html-react-parser'

export const EducationItem = ({icon, year, title, description}) => {
    return (
        <div className='education-item'>
            <div className='education-icon'>{icon}</div>
            <span className='education-date'>{year}</span>
            <h3 className='education-title'>{parse(title)}</h3>
            {/* <p className='education-description'>{description}</p> */}
        </div>
    )
}