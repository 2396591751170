import React from 'react'
import { TeachingThumbnail } from './components/teachingThumbnail'
import './teaching.css'
// import teaching from '../util/imgs/filler-img.jpg'
import teaching1 from '../util/imgs/teaching1.png'
import teaching2 from '../util/imgs/teaching2.png'
import teaching3 from '../util/imgs/teaching3.png'
import teaching4 from '../util/imgs/teaching4.png'
import teaching5 from '../util/imgs/teaching5.png'


export const Teaching = () => {

  return (
    <div className='teaching-container'>
        <div>
            <h1 className='section-title'>Teaching</h1>
            
        </div>
        <div className='teaching-description-container'>
        <p className = 'teaching-description'>My classroom is an amalgam of criticality, applied creativity, vulnerability,
            and reflection. Students exit my courses having developed an intellectual
            curiosity toward the ways in which communication structures society through
            historically situated rules, roles, and norms.</p>
        </div> 
        <div className='separator'></div>
        <h3 className='course-header'>Courses</h3>
        <div className='separator'></div>
        <div className='class-container'>
            <div className='class-details-container column-1'>
                <h2 className='class-title'>MAPC 614: Ethics, Health & Technology</h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column-2' >
                <img src={teaching1} alt="class img" className='class-image'/>
            </div>
            <div className='class-details-container column-4'>
                <h2 className='class-title'>SPE 330: Health Communication </h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column-3' >
                <img src={teaching2} alt="class img" className='class-image'/>
            </div>
            <div className='class-details-container column-5'>
                <h2 className='class-title'>SPE 310: Interpersonal Communication</h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column-6' >
                <img src={teaching3} alt="class img" className='class-image'/>
            </div>
            <div className='class-details-container column-8'>
                <h2 className='class-title'>SPE 208: Speech for Business & the Professions:</h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column-7' >
                <img src={teaching4} alt="class img" className='class-image'/>
            </div>
            <div className='class-details-container column-9'>
                <h2 className='class-title'>SPE 200: Oral Communication</h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column-10' >
                <img src={teaching5} alt="class img" className='class-image'/>
            </div>
            {/* <div className='class-details-container column'>
                <h2 className='class-title'></h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column ' >
                <img src={teaching} alt="class img" className='class-image'/>
            </div>
            <div className='class-details-container column'>
                <h2 className='class-title'></h2>
                <p className = 'course-description'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, dolorem corporis vero architecto amet quia, atque ea non nostrum fugit voluptates vitae ut quaerat sapiente mollitia omnis consectetur, facere accusantium.</p>
            </div>
            <div className='class-image-container column ' >
                <img src={teaching} alt="class img" className='class-image'/>
            </div> */}

        </div>
      <div className='separator'></div>
    </div>
  )
}


