export const resourcesData = [
    {
       title: 'Organizations advancing the study of Health Communication ',
        links: [
            'https://theberylinstitute.org/',
            'https://www.aachonline.org/',
            'https://www.facingourrisk.org/'
        ]
    },
    {
        title: 'Public Speaking',
        links: [
            'https://he.kendallhunt.com/product/business-communication-technological-world',
            'https://www.ted.com/read/ted-talks-the-official-ted-guide-to-public-speaking'
        ]
    },
    {
        title: 'Health Communication',
        links: [
            'https://www.waveland.com/browse.php?t=698',
            'https://www.abebooks.com/Communicating-Health-Personal-Cultural-Patricia-Geist-Martin/4642328502/bd',
            'https://onlinelibrary.wiley.com/doi/book/10.1002/9781119678816'
        ]
    },
    {
        title: 'Arts-Based Research & Qualitative Methods',
        links: [
            'https://www.guilford.com/books/Method-Meets-Art/Patricia-Leavy/9781462538973',
            'https://www.routledge.com/Poetic-Inquiry-Craft-Method-and-Practice/Faulkner/p/book/9781138486959',
            'https://www.abebooks.com/9781572734760/Postcritical-Ethnography-Reinscribing-Critique-Understanding-1572734760/plp',
            'https://www.routledge.com/Coming-to-Narrative-A-Personal-History-of-Paradigm-Change-in-the-Human/Bochner/p/book/9781598740387'
        ]
    },
    {
     title: 'Understanding and critiquing technology and organizational norms',
     links: [
        'https://www.ucpress.edu/book/9780520057289/the-constitution-of-society',
        'https://www.simonandschuster.com/books/Diffusion-of-Innovations-5th-Edition/Everett-M-Rogers/9780743258234',
        'https://store.hbr.org/product/the-origin-of-wealth-evolution-complexity-and-the-radical-remaking-of-economics/777X'
     ]   
    },
    {
        title: 'Understanding and critiquing social interaction and gendered norms',
        links: [
            'https://www.ucpress.edu/book/9780520272941/the-managed-heart',
            'https://www.routledge.com/Framing-Social-Interaction-Continuities-and-Cracks-in-Goffmans-Frame/Persson/p/book/9780367897246',
            'https://books.google.com/books/about/Gendered_Lives.html?id=Ui32QAAACAAJ'
        ]
    },
    {
        title: 'Interested in studying Communication at The University of Tampa? Here are some useful links:',
        links: [
            'https://www.ut.edu/academics/college-of-arts-and-letters/communication-degrees/bachelor-of-arts-in-communication-and-speech-studies',
            'https://www.ut.edu/graduate-degrees/ma-in-professional-communication',
            'http://viewer.zmags.com/publication/0c096323#/0c096323/1'
        ]
    }
]