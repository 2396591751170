import React from 'react';
import Profile from '../util/imgs/home/headshot.svg';
import { Link } from 'react-router-dom';
import  { FaArrowRight } from 'react-icons/fa';
import './home.css'


const Home = () => {
  return (
    <section className='home section grid'>
        <img src={Profile} alt= '' className='home__img' />

        <div className='home__content'>
            <div className='home__data'>
                <h1 className='home__title'>
                    <span>Meredith L. Clements, Ph.D.</span>
                </h1>
                <p className='home__description'>
                My research promotes careful examination of the patient experience within and outside of healthcare organizations.
                </p>

                <Link to='/about' className='button home-button'>
                    <p className='home-button-text'>More About Me </p>
                    <span className='button-icon home-button-icon'>
                        <FaArrowRight/>
                    </span>
                </Link>
            </div>
        </div>
        <div className='color__block'></div>
    </section>
  );
};

export default Home;