import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import '../research.css';

export const ResearchItem = ({ img, title, details }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <div className="research-item">
      <img src={img} alt='' className="research-img"/>

      <div className="research-hover" onClick={toggleModal}>
        <h3 className="research-title">{title}</h3>
      </div>

      {modal && (
        <div className="research-modal" onClick={toggleModal}>
          <div className="research-modal-content">
            <AiFillCloseCircle className='modal-close' onClick={toggleModal} />


              <h3 className='modal-title'>{title}</h3>

            <ul className="modal-list">
              {details.map(({ title, desc, button }, index) => (
                <li className="modal-item" key={index}>
                  <div>
                    <div className="item-title">{title}</div>
                    <div className="item-details">{desc}</div>
                    <div className='download-button'>{button}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
