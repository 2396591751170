import React from "react";
import useOpenController from "./useOpenController.jsx";
import {MdOutlineExpandMore} from 'react-icons/md'

export const ResourcesAccordion = ({ section}) => {
  const { isOpen, toggle } = useOpenController(false);
  console.log(section);
  return (
    <div className="accordion-container">
      <ExpendableColumn
        title={section.title}
        isOpen={isOpen}
        toggle={toggle}
      />
      {isOpen && <TextSection text={section.links} />}
      <div className="underline"></div>
    </div>
  );
};

export const ExpendableColumn = ({ title, toggle }) => {
  return (
    <div className="column-container" onClick={toggle}>
      <div className="column-text">{title}</div>
      <button className="expendable-button">
          <MdOutlineExpandMore size={30}/>
      </button>
    </div>
  );
};

export const TextSection = ({ text }) => {
    return (
      <div className="text-container">
        {text.map((link, index) => (
          <div key={index}>
            <a href={link}>{link}</a>
          </div>
        ))}
      </div>
    );
  };
  

