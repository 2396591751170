import React, { useState } from 'react'
import './research.css'
import { ResearchItem } from './components/researchItem'
import { researchData } from './components/researchData'



export const Research = () => {

  return (
    <section className='research-container'>
      <h1 className='section-title'>My <span>Research</span></h1>
      <p className='research-description'>
        My primary area of study is health communication. Within health, I focus on women's health,
         health information technology, and patient-centered communication. 
        <div className = 'research-description-separator'></div>
        My secondary area of interest is student-centered scholarship. I examine students'
         perspectives of learning experiences to enhance pedagogical efforts.
      </p>

      <div className='research-publication-container container grid research-grid'>
        {researchData.map((item) => {
          return <ResearchItem key={item.id} {...item} />;
        })}
      </div>
    </section>
  );

  



/* 
=======
      
      {/* <div className='research-publication-container'>
        {researchData.map((item) =>
          <ResearchThumbnail key={item.id} {...item} />
        )}
        </div> */


        // <div className='research-categories-container'>
        //   <div className='health-comms' style={{
        //     backgroundImage: `url(${healthCommsBackground})`,
        //     backgroundSize: 'cover'
        //     }}>
        //     <h4
        //       className={`section-title ${selectedSection === 'Health Communications' ? 'selected' : ''}`}
        //       onClick={() => handleSectionClick('Health Communications')}
        //       id = 'health-comms-title'
        //       style={{
        //         wordWrap: 'break-word',
        //       }}
        //     >
        //       Health <br></br> Communication
        //     </h4>
        //   </div>
        //   <div className='student-center'style={{
        //     backgroundImage: `url(${studentBackground})`,
        //     backgroundSize: 'cover'
        //     }}>
        //     <h4 
        //       className={`section-title ${selectedSection === 'Student Center Scholarships' ? 'selected' : ''}`}
        //       onClick={() => handleSectionClick('Student Center Scholarships')}
        //       id = 'student-center-title'
        //     >
        //       Student <br></br> Center <br></br> Scholarships
        //     </h4>
        //   </div>
        // </div>
  
        // {selectedSection === 'Health Communications' && showSelectedSection && (
        //   <div className='research-list-container'>
        //     <h4 className='section-title' id ="publications">Health Communications Publications</h4>
        //   <ResearchThumbnail
        //   Title = {`An Arts-Based Workshop Using Scalp Tattoos to Connect Students with Cancer Patients' Experiences of Chemotherapy and Hair Loss`}
        //   Authors = {`Meredith L. Clements, Ph.D., Preston W. Wimbish, and Rachel E. Wall`}
        //   Summary ={`This article advances arts-based pedagogy and qualitative methods in health
        //   communication. For over a year, I devoted part of the UT Board of Fellow Research Grant to
        //   mentor a team of undergraduate researchers who were interested in patient-centered
        //   communication and compassionate healthcare for women. This work resulted in the publication
        //   of a pilot study using workshopping and arts-based pedagogy to co-produce art that helped
        //   generate a greater sense of empathy toward women cancer patients. The article shares learning
        //   outcomes and &quot;how to&quot; steps for replicating the workshop.`}
        //   Journal = {`Association of American Medical Colleges`}
        //   JournalLink = {`https://journals.lww.com/academicmedicine/Abstract/2022/08000/An_Arts_Based_Workshop_Using_Scalp_Tattoos_to.41.aspx`}
        //   JournalImg = {AAMC}
        //   />
        //   <ResearchThumbnail
        //   Title = {`Lie to me: Tell me bald is beautiful`}
        //   Authors = {`Meredith L. Clements, Ph.D.`}
        //   Summary ={`health-based poetry and prose to rupture and evoke because that is what is
        //   needed in healthcare. We must produce evocative scholarship if we demand emotionally
        //   intelligent clinicians, and that is what I do. For those who have the good fortune of not
        //   experiencing cancer first or secondhand, this piece is a gut-wrenching glance into the relational
        //   turbulence that unfolds between husband and wife during chemotherapy.`}
        //   Journal = {`American College of Physicians`}
        //   JournalLink = {`https://www.acpjournals.org/doi/10.7326/M17-2270`}
        //   JournalImg = {ACP}
        //   />
        //   <ResearchThumbnail
        //   Title = {`Previving: How Unaffected Women with a BRCA1/2 Mutation Navigate Previvor Identity`}
        //   Authors = {`Hannah Getachew-Smith, Amy A. Ross, Courtney L. Scherr, Marleah Dean &
        //   Meredith L. Clements`}
        //   Summary ={`The term “previvor” refers to women (or men) who test positive for the BRCA genetic
        //   mutation but do not have a cancer diagnosis. “Pre” suggest the notion of “it's not if, but when I
        //   get cancer.” This piece unpacks the complexity of “previvor” as an identity marker from the
        //   perspectives of BRCA patients.`}
        //   Journal = {`Taylor & Francis: Health Communications`}
        //   JournalLink = {`https://pubmed.ncbi.nlm.nih.gov/31163995/`}
        //   JournalImg = {HealthComms}
        //   />
        //    <ResearchThumbnail
        //   Title = {`Ethnography`}
        //   Authors = {`Meredith L. Clements, Adolfo Lagomasino, Benjamin R. Bates`}
        //   Summary ={`This is a reference piece with a historical contextualization and extended description ofethnography and its use in health and healthcare.`}
        //   Journal = {`Wiley International Encyclopedia of Health Communication`}
        //   JournalLink = {`https://www.wiley.com/en-us/The+International+Encyclopedia+of+Health+Communication-p-9780470673959`}
        //   JournalImg = {IEHC}
        //   />
        //   <ResearchThumbnail
        //   Title = {`Medical Records, Electronic and Patient Portals`}
        //   Authors = {`Julie E. Volkman, Meredith L. Clements`}
        //   Summary ={`This is a reference piece about the U.S. government mandate to implement electronic medical records (EMR) as well as how EMR and portal technology are used in healthcare.`}
        //   Journal = {`Wiley International Encyclopedia of Health Communication`}
        //   JournalLink = {`https://www.wiley.com/en-us/The+International+Encyclopedia+of+Health+Communication-p-9780470673959`}
        //   JournalImg = {IEHC}
        //   />
        //   <ResearchThumbnail
        //   Title = {`Intersections of health and gender imperatives: stratified decision-making among women with a BRCA mutation`}
        //   Authors = {`Amy A. Ross Arguedas, Courtney L. Scherr, Marleah Dean, Hannah Getachew-Smith, Meredith Clements`}
        //   Summary ={`This is a qualitative health piece about decision-making among women with the
        //   genetic mutations “BRCA 1/2,” which are the “breast cancer genes.” Our findings discuss how
        //   older women who were married with children experienced alignment between gendered
        //   expectations and health expectations. In contrast, younger single women without children
        //   experienced conflicting decision-making concerning managing their health and enacting
        //   gendered expectations, resulting in the prioritization of gender imperatives.`}
        //   Journal = {`Springer Link: BioSocieties`}
        //   JournalLink = {`https://link.springer.com/article/10.1057/s41292-019-00154-8`}
        //   JournalImg = {Springer}
        //   />
        //   <ResearchThumbnail
        //   Title = {`“I will hold your hand through my disease:” Using poetic narrative to interpret the role of mother-patient during advanced-stage cancer`}
        //   Authors = {`Meredith L. Clements`}
        //   Summary ={`The piece is focused on “the patient” as a person whose
        //   identity resides beyond the hospital walls. Most patient-centered scholarship is focused on what
        //   happens within the clinic. That makes sense, and I have published good work that is focused on
        //   the traditional context of clinical communication and doctor-patient relationships. That said, this
        //   piece goes much further, taking “the patient” into the mundane, everyday space of motherhood,
        //   which is where she/the patient spends most...`}
        //   Journal = {`Journal of Mother Studies`}
        //   JournalLink = {`https://jourms.org/i-will-hold-your-hand-through-my-disease-using-poetic-narrative-to-interpret-the-role-of-mother-patient-during-advanced-stage-cancer/`}
        //   JournalImg = {JourMS}
        //   />
        //   <ResearchThumbnail
        //   Title = {`Assessing Patient Satisfaction: Using the Radiation Oncology Patient Satisfaction [ROPS] Questionnaire in a Private Practice Setting`}
        //   Authors = {`Loyd S. Pettegrew, Meredith L. Clements , Joshua M. Scacco and Robert Miller`}
        //   Summary ={`My research team developed a new method/measure to examine patient satisfaction.
        //   The measure is called the Radiation Oncology Patient Satisfaction (ROPS) questionnaire, and we
        //   tested its value and validity in a radiation oncology clinic. Participants' responses (n=950)
        //   indicated organizational setting was of vital importance when conducting patient-centered
        //   research on satisfaction. ROPS can be adjusted to measure satisfaction with chemotherapy or
        //   other modalities in addition to radiation treatment.`}
        //   Journal = {`Health Services Insights`}
        //   JournalLink = {`https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9379964/`}
        //   JournalImg = {HIS}
        //   />
        //   <ResearchThumbnail
        //   Title = {`Quality of life and clippers: Decision-making during chemotherapy-induced alopecia.`}
        //   Authors = {`Clements, M. L., Roscoe, L. A., &amp; Shahzad, M. M.`}
        //   Summary ={`This qualitative study introduced a strategic decision-making model clinicians can use
        //   to help patients cope with alopecia during chemotherapy.`}
        //   Journal = {`Gynecologic Oncology`}
        //   JournalLink = {`https://www.gynecologiconcology-online.net/article/S0090-8258(20)31652-8/fulltext`}
        //   JournalImg = {Gyne}
        //   />
        // </div>
        // )}
      /* </div>
      <div className='student-centered'> */
      /* <h4
          className={`section-title ${selectedSection === 'Student Center Scholarships' ? 'selected' : ''}`}
          onClick={() => handleSectionClick('Student Center Scholarships')}
        >
          Student Center Scholarships
        </h4> */

      // {/* </div>
      // <div className='student-centered'> */
      // /* <h4
      //     className={`section-title ${selectedSection === 'Student Center Scholarships' ? 'selected' : ''}`}
      //     onClick={() => handleSectionClick('Student Center Scholarships')}
      //   >
      //     Student Center Scholarships
      //   </h4> */}

//         {selectedSection === 'Student Center Scholarships' && showSelectedSection && (
//           <div className='research-list-container'>
//             <h4 className='section-title' id ="publications">Student Center Scholarships Publications</h4>
//           <ResearchThumbnail 
//           Title = {`Student Perceptions of Instructor-Student Rapport and Motivation In Hybrid Courses During COVID-19`} 
//           Authors = {`Bianca S. Candelaria* & Meredith L. Clements`} 
//           Summary = {`This interpersonal communication piece examines undergraduates' perceptions of
//           online and face-to-face learning during COVID. Our findings indicate a significant positive
//           relationship between instructor-student rapport and student motivation in both online and face-
//           to-face classes.`}
//           Journal = {`American Journal of Undergraduate Research`}
//           JournalLink = {`http://www.ajuronline.org/uploads/Volume_19_4/AJUR_Vol_19_Issue_4_March_2023_p31.pdf`}
//           JournalImg = {Ajur}
//           />
//           <ResearchThumbnail
//           Title = {`Making an impression @UTampaSpeech: A case study using Instagram at The 
//           Center for Public Speaking`}
//           Authors = {`Meredith L. Clements, Ph.D., Kristen A. Foltz, J.D., Sophia Gullo`}
//           Summary ={`The editor invited me to write a “best practices” piece, and I included a colleague.
//           Professor Foltz, and the student, Sophia, who helped me run The Speech Center's Instagram
//           account. During 2018-2019, The Speech Center performed an 11-month case study to assess the
//           use of Instagram to examine whether it was the right platform for the organization. The results
//           from this study are included and lessons are shared concerning how other Centers might use this
//           medium (or use another medium).`}
//           Journal = {`Communication Center Journal`}
//           JournalLink = {`https://files.eric.ed.gov/fulltext/EJ1269428.pdf`}
//           JournalImg = {CCJ}
//           />
//           <ResearchThumbnail
//           Title = {`17 Lines About Chemo`}
//           Authors = {`Meredith L. Clements`}
//           Summary ={`Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cupiditate consequatur est corrupti, sint inventore ipsum quibusdam et, perferendis earum deserunt quasi, ad voluptates debitis animi natus repudiandae facilis perspiciatis dolor.`}
//           Journal = {`JAMA Oncol.`}
//           JournalLink = {`https://jamanetwork.com/journals/jamaoncology/article-abstract/2575471`}
//           JournalImg = {JAMA}
//           />
//           <ResearchThumbnail
//           Title = {`The Mentoring Frame: Student-Preceptor Relationships in MedicalEducation`}
//           Authors = {`Meredith L. Clements`}
//           Summary ={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit quibusdam saepe nihil atque reiciendis consequatur quis voluptates nam debitis. Animi, pariatur explicabo. Ipsum assumenda suscipit commodi incidunt, eos ea quisquam!`}
//           Journal = {`Florida Communication Journal`}
//           JournalLink = {`https://web.s.ebscohost.com/abstract?direct=true&profile=ehost&scope=site&authtype=crawler&jrnl=10503366&AN=99419277&h=qvi%2fIg8nnpjSCEyWLfUaIQ0O21dxyVQ5SPYsiioNlf1pXLgIlhF17yVAgFccaDx9Cj4fEjX5BRrQ%2bErspMqp5Q%3d%3d&crl=c&resultNs=AdminWebAuth&resultLocal=ErrCrlNotAuth&crlhashurl=login.aspx%3fdirect%3dtrue%26profile%3dehost%26scope%3dsite%26authtype%3dcrawler%26jrnl%3d10503366%26AN%3d99419277`}
//           JournalImg = {FCJ}
//           />
//           <ResearchThumbnail
//           Title = {`Extra Credit and Decision-Making: Understanding College Students' Motivation to Attend On-Campus Events`}
//           Authors = {`Kristen A. Foltz, Meredith Clements, Aislinn Fallon, Alexandra Stinson`}
//           Summary ={`Few studies examine the incentives driving students' motivation to attend on-campus
//           events, and this study does just that. Using survey data, we examined undergraduates'
//           perceptions of extra credit and its connection to on-campus participation. This piece fits my
//           scholarly agenda because it is student-centered as well as applied and it has interpersonal
//           implications.`}
//           Journal = {`The Journal of Campus Activities Practice & Scholarship`}
//           JournalLink = {`https://www.naca.org/JCAPS/Documents/JCAPS_Issue_6_Foltz.pdf`}
//           JournalImg = {JCAPS}
//           />
//           <ResearchThumbnail
//           Title = {`Ethics, technology, and standard practice in communication centers: Proposing a continuing education credit program based on lessons learned from law, business, and healthcare`}
//           Authors = {`Meredith L. Clements, Ph.D., Kristen A. Foltz, J.D., Sean Sawicki`}
//           Summary ={`This article is applied and analytical. We examine technology and ethics in the context
//           of law, healthcare, and business then frame industry standards as lessons Communication
//           Centers can adopt to improve their organizational culture and everyday operations. Refer to p. 46
//           for “best practices” in the article.`}
//           Journal = {`Communication Center Journal`}
//           JournalLink = {`https://files.eric.ed.gov/fulltext/EJ1340572.pdf`}
//           JournalImg = {CCJ}
//           />
//         </div>
//         )}
//         </div>
//   )
// } */}

};