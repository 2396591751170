import React from 'react'
import './footer.css';
import { FaLinkedin } from 'react-icons/fa'
import { Contact } from './contact';
import {SiGooglescholar} from 'react-icons/si'
import {GiArchiveResearch} from 'react-icons/gi'
import {AiFillInstagram} from 'react-icons/ai'

export const Footer = () => {
  return (
    <div className='footer-container'>
      <div className='social-list-container'>
        <ul className='social-list'>
          <li>
             <a href='https://www.linkedin.com/in/meredith-clements-ph-d-aa2921149/' target='_blank' rel='noreferrer'><FaLinkedin className='social-icon'/><h3 className='links'>Linkedin</h3></a>
          </li>
          <li>
          <a href='https://www.instagram.com/mclemster' target='_blank' rel='noreferrer'><AiFillInstagram className='social-icon'/><h3 className='links'>Instagram</h3></a>
          </li>
          <li>
          <a href='https://scholar.google.com/citations?user=PjqRmwQAAAAJ&hl=en&oi=ao' target='_blank' rel='noreferrer'><SiGooglescholar className='social-icon'/><h3 className='links'>Google Scholar</h3></a>
          </li>
          <li>
          <a href='https://www.researchgate.net/profile/Meredith-Clements' target='_blank' rel='noreferrer'><GiArchiveResearch className='social-icon'/><h3 className='links'>Research Gate</h3></a>
          </li>
        </ul>
      </div>
      <div className='form-container'>
        <Contact/>
      </div>
    </div>
  )
}
