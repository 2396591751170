import React from "react"
import {FaGraduationCap} from 'react-icons/fa'

export const educationData = [
    {
        id: 1,
        category: 'education',
        icon: <FaGraduationCap className='education-icon' />,
        year: 2017,
        title:'Ph.D. Health Communication <span> <br/> University of South Florida </span>',
    },
    {
        id: 2,
        category: 'education',
        icon: <FaGraduationCap className='education-icon' />,
        year: 2015,
        title: `Graduate Certificate in Women's and Gender Studies <span> <br/> University of South Florida </span>`,
        // description: 'Thesis: Becoming preceptor, becoming student: Private practitioner-medical student relationships in medical education',
    },
    {
        id: 3,
        category: 'education',
        icon: <FaGraduationCap className='education-icon' />,
        year: 2013,
        title: 'M.A. Organizational Communication <span> <br/> University of North Carolina at Chapel Hill </span>',
        // description: 'Thesis: Becoming preceptor, becoming student: Private practitioner-medical student relationships in medical education',
    },
    {
        id: 4,
        category: 'education',
        icon: <FaGraduationCap className='education-icon' />,
        year: 2011,
        title: 'M.A. Communication, Culture & Technology <span> <br/> Georgetown University </span>',
        // description: 'Thesis: Patient portals: Adoption and implementation process of a new technology',
    },
    {
        id: 5,
        category: 'education',
        icon: <FaGraduationCap className='education-icon' />,
        year: 2008,
        title: `B.A. Communication Studies <span> <br/> The University of Alabama </span>`,
    },
    // {
    //     id: 5,
    //     category: 'education',
    //     icon: <FaGraduationCap className='education-icon' />,
    //     year: 2017,
    //     title:'Ph.D. Health Communication <span> University of South Florida </span>',
    // }
]