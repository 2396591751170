import React, {useState} from 'react'
import emailjs from 'emailjs-com';
import './contact.css'
import { ToastContainer, toast } from 'react-toastify';
import {FiSend} from 'react-icons/fi'
import 'react-toastify/dist/ReactToastify.css';



export const Contact = () => {
  // const emailjsTemplate = process.env.EMAIL_TEMPLATE
  // const emailjsKey = process.env.EMAIL_API_KEY
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  function sendEmail (e) {
    e.preventDefault();
    setIsSendingEmail(true);
    emailjs.sendForm('service_mcnk4fp', 'template_uk0v1nd', e.target, 'GSXBYOr5jnQmZODHi')
      .then((result) => {
        console.log(result.text);
        setIsSendingEmail(false);
        toast.success("Email sent successfully");
    }, (error) => {
        console.log(error.text);
        setIsSendingEmail(false);
        toast.error("An error occurred. Please try again.");
    });
  };
  return (
    <div className='contact-form-wrapper'>
    <form className='contact-form-container' onSubmit={sendEmail}>
      <h2 className='form-title'>Let's Connect</h2>
      <div className='form-field'>
        {/* <label>Full Name:</label> */}
        <textarea className='textarea'
        // cols= '50'
        // rows='1'
        placeholder='Name'
        type = 'text'
        name = 'name'
        required
        />
      </div>
      <div className='form-field'>
      {/* <label>Email:</label> */}
        <textarea className='textarea' 
        // cols= '50'
        // rows='1'
        placeholder='Email'
        type = 'email'
        name = 'email'
        required
        />
        </div>
        <div className='form-field form-field-large' >
          {/* <label>Message:</label> */}
          <textarea className='textarea textarea-large'
          // rows="10"
          // cols='50'
          placeholder='Message'
          type = 'text'
          name = 'message'
          // className='message-field'
          required
          />
        </div>
        <button className='button contact-button'>
        Send Email
        {/* <onClick = {sendEmail} disabled={isSendingEmail}/> */}
        <span className='button-icon contact-button-icon'>
          <FiSend/>
        </span>
      </button>
    </form>
    <ToastContainer/>
    </div>
  )
}
