import React from 'react'
import './publicSpeaking.css'
import ReactPlayer from 'react-player'

export const PublicSpeaking = () => {
  return (
    <div className='public-speaking-container'>
      <div className='public-speaking-title'>
      <h1 className='section-title'>Public <span>Speaking</span></h1>
      </div>
      <div className='video-container'>
        <div className='videos video-grid-2 row-1'>
        <p className='video-title'>Question Your Values, Unlock Your Potential: Understanding The Golden Rule and The Platinum Rule</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=rwz0Ho8e8cg&t=526s`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div>
        <div className='videos video-grid-2 row-2'>
        <p className='video-title'>Off Brand, Speech Contest, 2018, The Center for Public Speaking, Rebecca Mellman</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=zOiarLp8xIE`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div>
        <div className='videos video-grid-2 row-3'>
        <p className='video-title'>Valentine's Day, Speech Contest, 2019, The Center for Public Speaking, Alex Martinez</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=atMi-vj6oI8&t=2s`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div> 
        <div className='videos video-grid-2 row-4'>
        <p className='video-title'>Be Yourself & Love Yourself, Speech Contest, 2019, The Center for Public Speaking, Tamar Shimon</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=XEuHoP9Ft1s&t=1s`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div>
        <div className='videos video-grid-3 row-1'>
        <p className='video-title'>It Could Happen to Anyone, Speech Contest, 2019, The Center for Public Speaking, Rebecca Mellman</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=0O1_E8BgtqM&t=84s`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div>
        <div className='videos video-grid-3 row-2'>
        <p className='video-title'>The Call, Speech Contest, 2019, The Center for Public Speaking, Madison Sheriff</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=GNjhnPSyQVI&t=1s`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div >
        <div className='videos video-grid-3 row-3'>
        <p className='video-title'>The Radical Middle, Speech Contest, 2019, The Center for Public Speaking, Katy Glandorf</p>
        <ReactPlayer url={`https://www.youtube.com/watch?v=GdhA88gBrLU&t=81s`} className='vod-player' 
        // controls ={true} width="35vw" height="20vw" 
        />
        </div>

        {/* <div>
        <h3> Public Speaking Mentoring </h3>
        <p className='video-title'>Some Content about Mentoring Speech Skills</p>
        </div>
        <div>
        <h3> Reqeust Speak Event </h3>
        <p className='video-title'>Some content about potential collaborations like unviersity lectures</p>
        <button>Reach Out</button>
        </div>
        <div>
        <h3> Ted Talks </h3>
        <p className='video-title'>Some content about Ted Talks</p>
        </div> */}
      </div>
      </div>
  )
}

