import React from 'react';
import './App.css';
import Home from './pages/home';
// import Work from './pages/consulting';
import { Navbar } from './pages/components/navbar';
import { Route, Routes, useLocation } from 'react-router-dom';
import  { Footer } from './pages/components/footer';
import { About } from './pages/about';
import { Research } from './pages/research'
import { PublicSpeaking } from './pages/publicSpeaking';
import { Resources } from './pages/resources';
import { Teaching } from './pages/teaching';
import { ContactPage } from './pages/contactPage';


// import Node from "../src/util/imgs/mondrian.jpeg"

function App () {
    const location = useLocation();
    const isFooterVisible = ['/about', '/public-speaking', '/research', '/teaching', '/resources'].includes(location.pathname);
    
    return (
        <div>
            <Navbar/>

            <Routes>
                <Route index element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                {/* <Route path='/consulting' element={<Work/>}/> */}
                <Route path='/public-speaking' element={<PublicSpeaking/>}/>
                <Route path='/teaching' element={<Teaching/>}/>
                <Route path='/research' element={<Research/>}/>
                <Route path='/resources' element={<Resources/>}/>
                <Route path='/contact' element={<ContactPage/>}/>
            </Routes>
            {isFooterVisible && <Footer />}
        </div>


        
    )
}
export default App;