import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { links } from './navlinks';
import './navbar.css';

export const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className='navBar'>
      <div className={`${showMenu ? 'navBar-container show-menu' : 'navBar-container'}`}>
        <div className='navBar-toggle' onClick={() => setShowMenu(!showMenu)}>
          <span className='burger'></span>
          <span className='burger'></span>
          <span className='burger'></span>
        </div>
        <ul className={`navBar-list ${showMenu ? 'show-menu' : ''}`}>
          {links.map(({ name, icon, path }, index) => (
            <li className='navBar-list-items' key={index}>
              <NavLink
                to={path}
                className='navBar-link'
                activeClassName='active-nav'
                onClick={() => setShowMenu(false)}
              >
                <div className='navBar-icon'>{icon}</div>
                <h3 className='navBar-name'>{name}</h3>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
