import React, { useState, useEffect } from 'react';
import about1photo from '../../util/imgs/family.jpg';
import about2photo from '../../util/imgs/speech.jpg';
import about3photo from '../../util/imgs/bluejumpsuit.jpg';
import about4photo from '../../util/imgs/straz.jpg';
import './aboutPhotos.css';

export const AboutPhotos = () => {
  const aboutPhotos = [about1photo, about2photo, about3photo, about4photo];
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhotoIndex((prevIndex) =>
        prevIndex === aboutPhotos.length - 1 ? 0 : prevIndex + 1
      );
    }, 8000);
    return () => clearInterval(interval);
  }, [aboutPhotos.length]);

  return (
    <div className='about-wrapper'>
      <div
        className={`about-container ${
          currentPhotoIndex !== 0 ? 'fade-transition' : ''
        }`}
      >
        <div className='about-image-container'>
          <img
            src={aboutPhotos[currentPhotoIndex]}
            alt={`About ${currentPhotoIndex + 1}`}
            className='about-image'
          />
        </div>
      </div>
    </div>
  );
};

