import React from "react"
import {FaHome, FaUser, FaEnvelopeOpen} from 'react-icons/fa'
import {GiArchiveResearch} from 'react-icons/gi'
import {RiSpeakFill} from 'react-icons/ri'
import {GiPapers} from 'react-icons/gi'
import { FaMailBulk } from "react-icons/fa"
import { MdVideoCameraFront} from "react-icons/md"



export const links = [
    {
        id: 1,
        name: 'Home',
        icon: <FaHome className='navBar-icon' />,
        path: '/',
    },
    {
        id: 2,
        name: 'About',
        icon: <FaUser className='navBar-icon' />,
        path: '/about',
    },
    // {
    //     id: 3,
    //     name: 'Consulting',
    //     icon: <FaEnvelopeOpen className='navBar-icon' />,
    //     path: '/consulting',
    // },
    {
        id: 3,
        name: 'Research',
        icon: <GiArchiveResearch className='navBar-icon' />,
        path: '/research',
    },
    {
        id: 4,
        name: 'Teaching',
        icon: <RiSpeakFill className='navBar-icon' />,
        path: '/teaching',
    },

    {
        id: 5,
        name: 'Media',
        icon: <MdVideoCameraFront className='navBar-icon' />,
        path: '/public-speaking',
    },
    {
        id: 6,
        name: 'Resources',
        icon: <GiPapers className='navBar-icon' />,
        path: '/resources',
    },
    {
        id: 7,
        name: 'Contact',
        icon: <FaMailBulk className='navBar-icon' />,
        path: '/contact',
    }

]